import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List, Stack } from '@mui/material';
import { memo } from 'react';
import { useLocales } from '../../../locales';
import { isAuthorizedList } from '../../../utils/isAuthorisedSidebarItem';
import NavList from './NavList';
import { StyledSubheader } from './styles';
// ----------------------------------------------------------------------
function NavSectionVertical({ data, sx, ...other }) {
    const { translate } = useLocales();
    return (_jsx(Stack, { sx: sx, ...other, children: data.map((group) => {
            const key = group.subheader || group.items[0].title;
            return (_jsxs(List, { disablePadding: true, sx: { px: 2 }, children: [group.subheader && (_jsx(StyledSubheader, { disableSticky: true, children: `${translate(group.subheader)}` })), isAuthorizedList(group.items).map((list) => (_jsx(NavList, { data: list, depth: 1, hasChild: !!list.children }, list.title + list.path)))] }, key));
        }) }));
}
export default memo(NavSectionVertical);
